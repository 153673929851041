<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">设备类型管理</span>
        </div>
        <el-card class="el-main">
            <le-pagview ref="devicetypelist" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.deviceTypetable">
                <el-table ref="deviceTypetable" :data="tableData" :highlight-current-row="true"  style="width: 100%">
                    <el-table-column prop="name" label="设备名称"></el-table-column>
                    <el-table-column prop="name" label="设备类型"></el-table-column>
                    <el-table-column prop="pic" label="设备图片">
                        <template slot-scope="{ row }">
                            <el-image 
                                style="width:56px;height:56px" 
                                :src="row.pic ? row.pic : '../../assets/icon/device-type.png'"
                                :preview-src-list="[row.pic ? row.pic : '../../assets/icon/device-type.png']"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="设备价格（元）">
                        <template slot-scope="{ row }">
                            <span>{{ row.price?(row.price/100).toFixed(2):0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="socket" label="设备库存">
                        <template slot-scope="{ row }">
                            <span>{{ row.socket?row.socket:0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="110" align="center" fixed="right">
                        <template slot-scope="{ row }">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/edit.png" class="a-wh-16" @click="editDeviceType(row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <span>筛选出19种设备</span> -->
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal">种设备</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [],
            pageParam: {
                url: this.$Config.apiUrl.getDeviceType,
                method: "post",
                params: {
                    deviceCategroy: ''
                },
                freshCtrl: 1,
            },
        }
    },
    mounted () {
    },
    activated () {
		this.pageParam.freshCtrl++;
	},
    methods:{
        setTableData(data) {
            this.tableData = data.data;
            console.log(data.data);
        },
        editDeviceType (datas) {
            this.$router.push({
                path: '/device/device-type-edit',
                query:{
                    id: datas.id
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
    /deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
        height: 100% !important;
    }
</style>